import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { DeviceValidation } from 'src/app/services/validation-message/validation-message-list';

@Component({
  selector: 'app-device-create',
  templateUrl: './device-create.page.html',
  styleUrls: ['./device-create.page.scss'],
})
export class DeviceCreatePage implements OnInit {
  @Input() editData: any;
  data: any;
  validationsForm: FormGroup;
  @Input() heading : any;

  validation_messages = DeviceValidation;
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private apiService: ApiService,
  ) {}

  createEntry(data) {
    // console.log('data',data)
    let route = 'device'
    if (this.editData && this.editData.id) {
      this.apiService.postApi(route+'/update',this.validationsForm.value).subscribe(res => {
        // console.log('proppp',res);
        this.apiService.openToast(res.message);
          this.data = data;
          this.dismiss(); 
      }, err => {
        console.log(err)
      }) 
    } else {
      this.apiService.postApi(route+'/create',data).subscribe(res => {
        // console.log('perp',res);
        this.apiService.openToast(res.message);
          this.data = data;
          this.dismiss();
      }, err => {
        console.log(err)
      })
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      
      dismissed: true,
      data: this.data,

    });
  }

  ngOnInit() {
    
    //this.editData = JSON.parse(this.editData);
    this.validationsForm = this.formBuilder.group({
      id:   new FormControl(this.editData ? this.editData.id : ''),
      name: new FormControl(
        this.editData ? this.editData.name : '',
        Validators.required
      ),
      status: new FormControl(
        this.editData ? this.editData.status : '1',
      ),
    });
    
  }
}
