import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { ApiService } from '../services/api/api.service';

@Component({
  selector: 'app-delete-module',
  templateUrl: './delete-module.page.html',
  styleUrls: ['./delete-module.page.scss'],
})
export class DeleteModulePage implements OnInit {
// @Input() apiName:any;
detailsOfApi:any;
deleteMessage:any;
  constructor(
    private modalController : ModalController,
    private navParams:NavParams,
    private apiService:ApiService,
  ) { }

  ngOnInit() {
    this.detailsOfApi = this.navParams.get('apiName');
    this.deleteMessage = this.navParams.get('deleteMessage');
    // console.log(this.detailsOfApi);
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  confirmDelete(apiType:any) {
    // console.log('Delete confirmed');
    this.apiService.deleteApi(`${apiType}`).subscribe(res => {
      this.apiService.openToast(res?.message);
      this.dismiss();
    }, err => {
          console.log(err)
    })
  }

  cancelDelete() {
    console.log('Delete canceled');
    this.dismiss();
  }

}
