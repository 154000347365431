import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-lead-status',
  templateUrl: './lead-status.page.html',
  styleUrls: ['./lead-status.page.scss'],
})
export class LeadStatusPage implements OnInit {
  @Input() status: any;
  SortType = SortType;
  row:any;
  constructor(
    private modalController:ModalController,
    private datePipe:DatePipe
  ) { }

  ngOnInit() {
    this.row = this.status
    this.row.forEach(element => {
      element.dated = this.datePipe.transform(element.dated, 'dd-MM-yyyy h:mm:ssa')
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

}
