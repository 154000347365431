import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AddOnValidation } from 'src/app/services/validation-message/validation-message-list';

@Component({
  selector: 'app-add-on-create',
  templateUrl: './add-on-create.page.html',
  styleUrls: ['./add-on-create.page.scss'],
})
export class AddOnCreatePage implements OnInit {

  @Input() editData: any;

  data: any;
  validationsForm: FormGroup;
  @Input() heading : any;

  validation_messages = AddOnValidation;
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private apiService: ApiService,
  ) { }


  createEntry(data) {
    let route = 'addons'
    if (this.editData && this.editData.id) {
      this.apiService.putApi(route+'/'+this.editData.id,this.validationsForm.value).subscribe(res => {
        this.apiService.openToast(res.message);
        // console.log("response==>",res);
          this.data = data;
          this.dismiss();
      }, err => {
        console.log(err);
      }) 
    } else {
      this.apiService.postApi(route,data).subscribe(res => {
        // console.log('respo',res.data);
        this.apiService.openToast(res.message);
          this.data = data;
          this.dismiss();
      }, err => {
        console.log(err)
      })
    }
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
      data: this.data,
      
    });
  }

  ngOnInit() {
      // console.log(this.editData);
    // this.editData = JSON.parse(this.editData);
    this.validationsForm = this.formBuilder.group({
      id: new FormControl(this.editData ? this.editData.id : ''),
      name: new FormControl(
        this.editData ? this.editData.name : '',
        Validators.required
      ),
      price:new FormControl(
        this.editData ? this.editData.price : '',
        Validators.required
      ),
      status:new FormControl(
        this.editData ? this.editData.status : '1',
      ),
    });
  }

}
